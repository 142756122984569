import React, { useState } from "react"

import PropTypes from "prop-types"

import { convertToId } from "../../../helpers/helpers"

import "./sidebar-contents.scss"

export const SidebarContents = ({ headers, className }) => {
  const [isActive, setIsActive] = useState(false)

  const handleOnClick = e => {
    e.preventDefault()
    setIsActive(!isActive)
  }

  const goToElement = e => {
    e.preventDefault()
    const targetId = e.target.getAttribute("data-target")
    document.getElementById(targetId).scrollIntoView()
  }

  return (
    <aside className={`sidebar-contents ${className}`}>
      <header className="d-flex align-items-center">
        <h3 className="d-inline-block mb-0">Contents</h3>

        <button
          className={`
            btn 
            btn-light 
            btn-sm 
            sidebar-contents-header-link 
            ${isActive ? "is-active" : ""}
          `}
          aria-label="Open table of contents"
          onClick={handleOnClick}
        >
          {isActive ? "Close" : "Open"}
        </button>
      </header>

      <hr />

      <main aria-label="table of contents main">
        {headers.length ? (
          <nav
            aria-label="table of contents nav"
            className={`
              sidebar-contents-list 
              ${isActive ? "" : "is-hidden"}
            `}
          >
            <ul>
              {headers.map(header => (
                <li key={header}>
                  <a
                    data-target={convertToId(header)}
                    href={`#${convertToId(header)}`}
                    onClick={goToElement}
                  >
                    {header}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        ) : (
          <p>No contents available.</p>
        )}
      </main>
    </aside>
  )
}

SidebarContents.propTypes = {
  headers: PropTypes.array,
  className: PropTypes.string,
}
